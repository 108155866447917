#home{
    padding-top: 20vh;
    width: 100%;
    min-height: 840px;
}
.connectWallet:hover{
    transform: scale(0.95) !important; 
    box-shadow: none !important;
    opacity: 0.7;
}
#leftSide{
    width: 70%;
    height: 100%;
}
.slick-prev{
    left: -75px !important;
}
.slick-next{
    right: -75px !important;
}
.slick-dots{
    bottom:-75px !important;
}
.slick-dots li button:before{
    color:#d7d7d7 !important;
}
.slick-dots li.slick-active button:before{
    color:white !important;
}
#rightSide{
    width: 30%;
    height: 100%;
}
#topSpan{
    margin-bottom: 15px;
}
#topSpan span{
    background: hsl(159deg 73% 55%);
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 11px;
    margin-right: 15px;
    font-weight: 700;
}

#headerBG{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: -5;
    opacity: 0.3;
    background: url('../assets/img/picture/bg.gif') no-repeat center center ; 
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-position: center 0px !important;
    width: 70vw;
    height: 70vh;
}
#imageWrapper{
    flex-direction: column;
}
.topSocial img{
    width: 20px;
    margin-top: 8px;
}
.progressTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.progressTitle h2{
    font-size: 14px;
    color: #86adff;
}
.progressTitle span{
    font-size: 10px;
    font-weight: 100;
    margin-bottom: -5px;
}
.progressFooter{
    text-align: right;
    margin-top: 0px;
}
.progressFooter span{
    font-size: 14px;
    font-weight: bold;
}
#imageWrapper img{
    z-index: 9;
    width: 100%;    
    height: auto;
    border-radius: 35px;
    margin-bottom: 50px;
}
.progress{
    width: 100%;
    max-width: 100% !important;
}
#leftSide h1{
    position: relative;
}
#leftSide h1::after{    
    width: 150px;
    height: 5px;
    background: white;
    position: absolute;
    top: 35px;
    left: -200px;
}
#leftSide h2{
    max-width: 90%;
    padding: 15px 0 50px 0;
    line-height: 1.5rem;
    padding-bottom: 15px;
}
.btnWrapper{
    max-width: 190px;
}
.social-item{
    padding-bottom: 30px;
}
.social-item img{
    width: 25px;
    padding-left: 0;
    transition: 0.4s all;

}
#enleapLogo{
    width: 25px;
    filter: brightness(5);
    margin-bottom: -3px;
}
.social-item a{
    padding-left: 0 !important;
    padding-right: 45px !important;
}
.social-item img:hover{
    transform: scale(1.15);
    opacity: 0.7;
}
#bottomSpan{
    display:flex;    
    margin-bottom: 60px;
}
.spanItem{
    margin-right: 80px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}
.spanItem label{
    font-size: 13px;
    font-weight: 300;
}
.spanItem span{
    font-size: 18px;
    font-weight: 700;
}

#imageWrapper{
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 9;
}

#stepSection{
    padding: 80px 0px;
    margin-top: -100px;
}

#aboutSection h1{
    margin-top: 150px;
    margin-bottom: 25px;
    line-height: 30px;
}

#aboutSection button{
    width: auto;
    margin-top: 35px;
    padding: 13px 25px;
}
#stepSection h1 span{
    color: #3b46ff;
}
#aboutSection{
    background: url('../assets/img/picture/galaxy.png') no-repeat center center ; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100%;
    background-position:center 0px !important;
    min-height: 800px;
}

@media (max-width: 960px) {

    #home{
        flex-direction: column;
    }
    #leftSide h2{
        padding: 15px 0 50px 0;
        max-width: 100%;
    }
    #imageWrapper{
        width: 80%;
        justify-content: center;
        margin:auto;
    }
    #leftSide{
        width: 100%;
        order: 2;
        margin: 50px 0;
    }
    
    #rightSide{
        width: 100%;
        order: 1;
        margin-top: 30px;
    }
    .btnWrapper{
        margin: 0 auto;
    }
    #aboutSection{
        background-size: cover;
    }
}

@media (max-width: 800px) {
    #launchpadContent, #launchpadContent .btnWrapper{
        flex-direction: column;
    }
    #launchpadContent .btnWrapper span{
        margin-top: 25px;
        font-size: 15px;
    }
}

@media (max-width: 560px) {

    #leftSide{
        margin: 80px 0 50px 0;
    }

}


.progress {
    width: 100%;
    height: 10px !important;
    background: #e1e4e8;
    border-radius: 5px !important;
    overflow: hidden !important;
  }
  .progress .progress-bar {
    display: block;
    height: 100%;
    background: linear-gradient(90deg, #2d10af, #613dff 17%, #9771ff 34%, #63d3ff 51%, #2d10af 68%, #613dff 85%, #9771ff);
    background-size: 300% 100%;
    -webkit-animation: progress-animation 2s linear infinite;
            animation: progress-animation 2s linear infinite;
  }
  
  @-webkit-keyframes progress-animation {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: 0;
    }
  }
  
  @keyframes progress-animation {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: 0;
    }
  }


  #homeLoader{
    width: 100%;
    min-height: 840px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #homeLoader p{
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
  }
  #homeLoader img{
    width: 50px;
  }
#launchpadContent{
    padding-top: 20vh;
    align-items: flex-start;
    position: relative;
    min-height: 88vh;
}
#launchpadContent #leftSide{
    padding-left: 10%;
}

#launchpadContent #leftSide h2{
    max-width: 100%;
}
#launchpadContent #leftSide h1::after{
    left: calc(100% + 50px);
}

#launchpadContent .btnWrapper{
    display: flex;
    align-items: center;
    max-width: 100%;
}

#launchpadContent .connectWallet{
    max-width: 280px;
    margin-right: 25px;
    box-shadow: 5px 5px 5px #247099;
    font-size: 13px;
}
#launchpadContent .connectWallet.notwl{
    max-width: 180px;
}

#launchpadContent .btnWrapper span{
    font-size: 16px;
    font-weight: 400;
    opacity: 0.9;
}

#loader img{
    width: 100px;
}
#loader p{
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #8b8b8b;
}
#loader{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color:white;
}

#projectBack{
    position: absolute;
    margin-top: -60px;
    font-size: 24px;
}

.connectWallet small{
    display: block;
    font-weight: 100;
    font-size: 9px;
    opacity: 0.8;
}

.responseModal .modal-dialog{
    position: absolute!important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%)!important;
    transform: translate(-50%,-50%)!important;
    margin: 0!important;
    width: 350px;
    height: 250px;
}
.promptClose {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: #777;
}
.modal-content{
    height: 100%;
    border-radius: 50px !important;
    border: 1.5px solid black !important;

}
.modal-body{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-body p{
    color: #496581;
    font-size: 15px;
    text-align: center;
}
.modal-backdrop.show{
    opacity: 0.3 !important;
}
#rabbitGIF{
    width: 45px;
    position: absolute;
    right: 20px;
    bottom: 15px;
}

#leftEar{
    position: absolute;
    top: -33px;
    left: -48px;
    width: 41px;
    transform: rotate(-52deg);
    animation: left-ear 2s linear infinite;
}
#rightEar{
    position: absolute;
    top: -96px;
    left: 44px;
    width: 65px;
    transform: rotate(352deg);
    z-index: -1;
    filter: contrast(0.8);
    animation: right-ear 10s linear infinite;
}
#hair{
    position: absolute;
    top: -2px;
    left: 10px;
    width: 50px;
    transform: rotate(-12deg);
}

@keyframes right-ear {
    0% {
        transform: rotate(352deg);
    }
    30%{
        transform: rotate(345deg);
    }
    70%{
        transform: rotate(354deg);
    }
    100% {
        transform: rotate(352deg);
    }
  }










.footer{
    font-weight: 700;
}











  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  body {
    background: #6dd3e7;
    padding: 100px;
    overflow-x: hidden;
  }
  
  .cloud {
    -webkit-animation: clouds 60s infinite linear;
    -moz-animation: clouds 60s infinite linear;
    -ms-animation: clouds 60s infinite linear;
    -o-animation: clouds 60s infinite linear;
    animation: clouds 60s infinite linear;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    position: relative;
    margin: 33px 0 0 0;
    width: 54px;
    height: 5px;
    background: #f7e7eb;
  }
  .cloud.tiny {
    -moz-transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
  .cloud.small {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .cloud.normal {
    -moz-transform: scale(2, 2);
    -ms-transform: scale(2, 2);
    -webkit-transform: scale(2, 2);
    transform: scale(2, 2);
  }
  .cloud.large {
    -moz-transform: scale(4, 4);
    -ms-transform: scale(4, 4);
    -webkit-transform: scale(4, 4);
    transform: scale(4, 4);
  }
  .cloud div {
    -moz-box-shadow: inset -2px -3px 0 0 #f7e7eb;
    -webkit-box-shadow: inset -2px -3px 0 0 #f7e7eb;
    box-shadow: inset -2px -3px 0 0 #f7e7eb;
    position: absolute;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    left: -3px;
    bottom: 0;
    background: #fafbf0;
    z-index: 10;
  }
  .cloud div:first-child + div {
    -moz-transform: scale(1.6, 1.6);
    -ms-transform: scale(1.6, 1.6);
    -webkit-transform: scale(1.6, 1.6);
    transform: scale(1.6, 1.6);
    margin: 0 0 4px 13px;
    z-index: 9;
  }
  .cloud div:first-child + div + div {
    -moz-transform: scale(2.4, 2.4);
    -ms-transform: scale(2.4, 2.4);
    -webkit-transform: scale(2.4, 2.4);
    transform: scale(2.4, 2.4);
    margin: 0 0 9px 32px;
    z-index: 8;
  }
  .cloud div:first-child + div + div + div {
    -moz-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    -webkit-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
    margin: 0 0 2px 50px;
    z-index: 7;
  }
  
  @-webkit-keyframes clouds {
    0% {
      left: -100%;
      opacity: 1;
    }
    100% {
        opacity: 0.5;
      left: 120%;
    }
  }
  @-moz-keyframes clouds {
    0% {
      left: -100%;
      opacity: 1;
    }
    100% {
      left: 120%;
      opacity: 0.5;
    }
  }
  @-ms-keyframes clouds {
    0% {
      left: -100%;
      opacity: 1;
    }
    100% {
      left: 120%;
      opacity: 0.5;
    }
  }
  @keyframes clouds {
    0% {
      left: -100%;
      opacity: 1;
    }
    100% {
      left: 120%;
      opacity: 0.5;
    }
  }
  .cloud-1 {
    -webkit-animation-duration: 183s;
    -moz-animation-duration: 183s;
    -ms-animation-duration: 183s;
    -o-animation-duration: 183s;
    animation-duration: 183s;
    margin-left: 20%;
  }
  
  .cloud-2 {
    -webkit-animation-duration: 69s;
    -moz-animation-duration: 69s;
    -ms-animation-duration: 69s;
    -o-animation-duration: 69s;
    animation-duration: 69s;
    margin-left: 90%;
  }
  
  .cloud-3 {
    -webkit-animation-duration: 92s;
    -moz-animation-duration: 92s;
    -ms-animation-duration: 92s;
    -o-animation-duration: 92s;
    animation-duration: 92s;
    margin-left: 50%;
  }
  
  .cloud-4 {
    -webkit-animation-duration: 102s;
    -moz-animation-duration: 102s;
    -ms-animation-duration: 102s;
    -o-animation-duration: 102s;
    animation-duration: 102s;
    margin-left: 43%;
  }
  
  .cloud-5 {
    -webkit-animation-duration: 115s;
    -moz-animation-duration: 115s;
    -ms-animation-duration: 115s;
    -o-animation-duration: 115s;
    animation-duration: 115s;
    margin-left: 83%;
  }
  
  .cloud-6 {
    -webkit-animation-duration: 79s;
    -moz-animation-duration: 79s;
    -ms-animation-duration: 79s;
    -o-animation-duration: 79s;
    animation-duration: 79s;
    margin-left: 73%;
  }
  
  .cloud-7 {
    -webkit-animation-duration: 69s;
    -moz-animation-duration: 69s;
    -ms-animation-duration: 69s;
    -o-animation-duration: 69s;
    animation-duration: 69s;
    margin-left: 69%;
  }
  
  .cloud-8 {
    -webkit-animation-duration: 71s;
    -moz-animation-duration: 71s;
    -ms-animation-duration: 71s;
    -o-animation-duration: 71s;
    animation-duration: 71s;
    margin-left: 100%;
  }
  
  .cloud-9 {
    -webkit-animation-duration: 61s;
    -moz-animation-duration: 61s;
    -ms-animation-duration: 61s;
    -o-animation-duration: 61s;
    animation-duration: 61s;
    margin-left: 10%;
  }
  
  .cloud-10 {
    -webkit-animation-duration: 76s;
    -moz-animation-duration: 76s;
    -ms-animation-duration: 76s;
    -o-animation-duration: 76s;
    animation-duration: 76s;
    margin-left: 14%;
  }
  
  .cloud-11 {
    -webkit-animation-duration: 56s;
    -moz-animation-duration: 56s;
    -ms-animation-duration: 56s;
    -o-animation-duration: 56s;
    animation-duration: 56s;
    margin-left: 73%;
  }
  
  .cloud-12 {
    -webkit-animation-duration: 43s;
    -moz-animation-duration: 43s;
    -ms-animation-duration: 43s;
    -o-animation-duration: 43s;
    animation-duration: 43s;
    margin-left: 51%;
  }
  

  #cloudSection{
    position: absolute;
    z-index: -40;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }